.btn-link {
  color: #000 !important;
}
.btn-link:hover {
  opacity: 0.8;
}

.react-select.primary
  .react-select__control
  .react-select__value-container--has-value
  .react-select__single-value,
.react-select.primary
  .react-select__control
  .react-select__value-container--has-value
  .react-select__multi-value__label {
  color: #000;
}

.navbar a {
  color: #000 !important;
}

.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
  color: #000;
  background-color: #fff;
}
.navbar .dropdown-menu .dropdown-item:hover,
.navbar .dropdown-menu .dropdown-item:focus {
  background-color: #f5f5f5;
  color: #333333;
  opacity: 1;
  text-decoration: none;
}
.rdw-editor-wrapper a {
  color: #000;
}
.nav-tabs a {
  color: #000;
}
.form-control:focus {
  /* background-color: #FFFFFF; */
  border: 1px solid #FB404B !important;
  /* box-shadow: none;
  outline: 0 !important;
  color: #333333; */
}
.react-select .react-select__control.react-select__control--is-focused, .react-select .react-select__control.react-select__control--is-focused:hover {
  border: 1px solid #FB404B !important;
  box-shadow: none; }
.navbar .navbar-nav .nav-item .nav-link {    
    padding: 10px 25px;   
}

.has-success .form-control:focus {
  border-color: #000;
  color: #000; }

.headerCls {
  text-align: left !important;
}
.ReactTable .rt-tbody .rt-td:last-child {

  text-align: right;
}