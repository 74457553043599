table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
}
table.dataTable td,
table.dataTable th {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}
table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block;
}
div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}
div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 8px;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: block;
  font-family: 'Glyphicons Halflings';
  opacity: 0.5;
}
table.dataTable thead .sorting:after {
  opacity: 0.2;
  content: "\e150";
  /* sort */
}
table.dataTable thead .sorting_asc:after {
  content: "\e155";
  /* sort-by-attributes */
}
table.dataTable thead .sorting_desc:after {
  content: "\e156";
  /* sort-by-attributes-alt */
}
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  color: #eee;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}
div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}
table.dataTable.table-condensed > thead > tr > th {
  padding-right: 20px;
}
table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
  top: 6px;
  right: 6px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}
table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}
table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  padding-left: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
  padding-right: 0;
}


table.dataTable{

    .btn-simple.btn-icon{
        padding: 3px;
    }

    thead{
        .sorting,
        .sorting_asc,
        .sorting_desc,
        .sorting_asc_disabled,
        .sorting_desc_disabled{

            &:after{
                position: relative;
                display: inline-block;
                bottom: 0px;
                right: -7px;
                font-family: 'FontAwesome';
                opacity: 0.8;
            }

        }

        .disabled-sorting.sorting,
        .disabled-sorting.sorting_asc,
        .disabled-sorting.sorting_desc,
        .disabled-sorting.sorting_asc_disabled,
        .disabled-sorting.sorting_desc_disabled{
                &:after{
                    display: none;
                }

        }

        .sorting{
            &:after{
                opacity: 0.4;
                content: "\f0dc";
            }
        }

        .sorting_asc{
            &:after{
                content: "\f0de";
                top: 3px;
            }
        }

        .sorting_desc{
            &:after{
                content: "\f0dd";
                top: -3px;
            }
        }
    }

    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td{
        padding: 8px !important;
        outline: 0;
    }

    > thead > tr > th{
        border: none;
    }
}

.fresh-datatables{
    .pagination {
        > li > a,
        > li:first-child > a,
        > li:first-child > span,
        > li:last-child > a,
        > li:last-child > span{
            border-radius: 25px;
        }
    }
}



.dataTables_paginate{
    a{
            outline: 0;
    }
}

table.dataTable.dtr-inline.collapsed {
    > tbody > tr > td.child,
    > tbody > tr > th.child,
    > tbody > tr > td.dataTables_empty{
        cursor: default !important;
    }

    > tbody > tr > td.child,
    > tbody > tr > th.child,
    > tbody > tr > td.dataTables_empty{
        &:before{
            display: none !important;
        }
    }

    > tbody > tr > td:first-child,
    > tbody > tr > th:first-child{
        position: relative;
        padding-left: 30px !important;
        cursor: pointer;
    }

    > tbody > tr > td:first-child,
    > tbody > tr > th:first-child{
        &:before{
            top: 50%;
            margin-top: -9px;
            left: 4px;
            height: 18px;
            width: 18px;
            display: block;
            position: absolute;
            color: #518607;
            border: 0px solid white;
            border-radius: 14px;
            box-shadow: 0 0 3px #444;
            box-sizing: content-box;
            text-align: center;
            font-family: 'Courier New', Courier, monospace;
            line-height: 18px;
            content: '+';
            background-color: #FFF;
        }
    }

    > tbody > tr.parent > td:first-child,
    > tbody > tr.parent > th:first-child{
        &:before{
            content: '-';
            color: #ED362C;
        }
    }

    > tbody > tr.child td{
        &:before{
            display: none;
        }
    }
}


table.dataTable.dtr-inline.collapsed.compact{
    > tbody > tr > td,
    > tbody > tr > th{
        &:first-child{
            padding-left: 27px;
        }
    }

    > tbody > tr > td,
    > tbody > tr > th{
        &:first-child:before{
            top: 5px;
            left: 4px;
            height: 14px;
            width: 14px;
            border-radius: 14px;
            line-height: 14px;
            text-indent: 3px;
        }
    }
}

table.dataTable.dtr-column{
    > tbody > tr > td.control,
    > tbody > tr > th.control{
        position: relative;
        cursor: pointer;
    }

    > tbody > tr > td.control,
    > tbody > tr > th.control{
        &:before{
            top: 50%;
            left: 50%;
            height: 16px;
            width: 16px;
            margin-top: -10px;
            margin-left: -10px;
            display: block;
            position: absolute;
            color: white;
            border: 2px solid white;
            border-radius: 14px;
            box-shadow: 0 0 3px #444;
            box-sizing: content-box;
            text-align: center;
            font-family: 'Courier New', Courier, monospace;
            line-height: 14px;
            content: '+';
            background-color: #31b131;
        }
    }

    > tbody > tr.parent td.control,
    > tbody > tr.parent th.control{
        &:before{
            content: '-';
            background-color: #d33333;
        }
    }
}

table.dataTable{
    > tbody > tr.child {
        padding: 0.5em 1em;
    }

    > tbody > tr.child{
        &:hover{
            background: transparent !important;
        }
    }

    > tbody > tr.child ul {
        display: inline-block;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    > tbody > tr.child ul li {
        border-bottom: 1px solid #efefef;
        padding: 0.5em 0;
    }

    > tbody > tr.child ul li{
        &:first-child{
            padding-top: 0;
        }
    }

    > tbody > tr.child ul li{
        &:last-child{
            border-bottom: none;
        }
    }

    > tbody > tr.child span.dtr-title {
        display: inline-block;
        min-width: 75px;
        font-weight: bold;
    }
}

div.dtr-modal {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    padding: 10em 1em;

    div.dtr-modal-display {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 50%;
        overflow: auto;
        margin: auto;
        z-index: 102;
        overflow: auto;
        background-color: #f5f5f7;
        border: 1px solid black;
        border-radius: 0.5em;
        box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
    }

    div.dtr-modal-content {
        position: relative;
        padding: 1em;
    }

    div.dtr-modal-close {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 22px;
        height: 22px;
        border: 1px solid #eaeaea;
        background-color: #f9f9f9;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
        z-index: 12;
    }

    div.dtr-modal-close{
        &:hover{
            background-color: #eaeaea;
        }
    }

    div.dtr-modal-background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 101;
        background: rgba(0, 0, 0, 0.6);
    }
}

.data-tables{
    .pagination{
        .paginate_button.page-item{
            &.first,
            &.previous,
            &.next,
            &.last{
                .page-link{
                    border-radius: 25px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    div.dtr-modal div.dtr-modal-display {
        width: 95%;
    }

    .data-tables{
        .pagination{
            .paginate_button.page-item{
                &.first,
                &.last{
                    display: none;
                }
            }
        }
    }
}
