body {
  overflow-x: hidden;
}

h4 {
  font-weight: bold;
  margin: 15px 0;
}

.modal-80w {
  max-width: 80vw;
}

.rdw-editor-toolbar.editor-toolbar {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.rdw-editor-main.editor-content {
  padding: 6px 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
}